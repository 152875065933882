import React from "react";

// components
import NaviBar from "../components/navbar/NaviBar";
import HeroVideo from "../components/hero/HeroVideo";
import Spotlight from "../components/spotlight/Spotlight";
import HeroSection1 from "../components/hero/HeroSection1";
import HeroSection2 from "../components/hero/HeroSection2";
import WhatIs from "../components/whatis/WhatIs";
import HowWeUse from "../components/howWeUse/HowWeUse";
import SocialMedia from "../components/socialMedia/SocialMedia";
import AlotOfData from "../components/aLotOfData/AlotOfData";
import FutureGrowth from "../components/futureGrowth/FutureGrowth";
import TargetProperties from "../components/targetProperties/TargetProperties";
import InvestmentObj from "../components/investmentObj/InvestmentObj";
import AssetGallery from "../components/assetGallery/AssetGalleryContainer";
import InformationCenter from "../components/informationCenter/InformationCenter";
import StartInvesting from "../components/startInvesting/StartInvesting";
import Footer from "../components/footer/Footer";
import RtiButton from "../components/RTI-button/RtiButton";

const Home = () => {
  return (
    <>
      <NaviBar />
      <HeroVideo />
      <Spotlight />
      {/* <HeroSection1 /> */}
      {/* <HeroSection2 /> */}
      {/* <WhatIs /> */}
      {/* <HowWeUse /> */}
      {/* <SocialMedia /> */}
      {/* <AlotOfData /> */}
      {/* <FutureGrowth /> */}
      {/* <TargetProperties /> */}
      {/* <InvestmentObj /> */}
      {/* <AssetGallery /> */}
      <InformationCenter />
      {/* <StartInvesting /> */}
      <Footer />
      {/* <RtiButton /> */}
    </>
  );
};

export default Home;
