import React, { useState } from "react";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsOfUse from "./TermsOfUse";

const LoginFooter = () => {
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showTerms, setShowTerms] = useState(false);

  const toggleModalShow = (type) => {
    if (type === "privacy") {
      setShowPrivacy(true);
    } else if (type === "terms") {
      setShowTerms(true);
    } else {
      return null;
    }
  };

  // hides the modal based on state object whether privacy or terms is true, it sets to false.
  const toggleModalHide = () => {
    if (showPrivacy === true) {
      setShowPrivacy(false);
    } else {
      setShowTerms(false);
    }
  };

  return (
    <>
      <div className="login-footer-wrapper">
        <div className="login-footer-content-container">
          <div className="login-footer-column">
            <span
              className="login-footer-text-top"
              onClick={() => toggleModalShow("privacy")}
            >
              Privacy Policy
            </span>
            <span className="login-footer-text-bottom">
              We have updated our Privacy Policy. Click above to review.
            </span>
          </div>
          <div className="login-footer-column">
            <span
              className="login-footer-text-top"
              onClick={() => toggleModalShow("terms")}
            >
              Terms Of Use
            </span>
            <span className="login-footer-text-bottom">
              We have updated our Terms of Use. Click above to review.
            </span>
          </div>
        </div>
      </div>
      {/* <!-- The Modal --> */}
      <div
        id="myModal"
        className={
          showPrivacy === false && showTerms === false
            ? "modal-hide"
            : "modal-show"
        }
      >
        {/* <!-- Modal content --> */}
        <div className="modal-content-cust-login">
          <div className="modal-header-cust">
            <span
              className="modal-close-cust"
              onClick={() => toggleModalHide()}
            >
              &times;
            </span>
            <h5 className="fw-4">
              {showPrivacy === true ? "Privacy Policy" : "Terms Of Use"}
            </h5>
          </div>
          <div className="modal-body-cust">
            {showPrivacy === true ? <PrivacyPolicy /> : <TermsOfUse />}
          </div>
          <div className="close_button_bottom">
            <button
              onClick={() => toggleModalHide()}
              className="modal_close_button"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginFooter;
