import React from "react";
import "./fItems.css";

const FItems = ({
  fitemId,
  isVideo,
  isHosted,
  downloadUrl,
  modalClick,
}) => {
  const imgURL = process.env.REACT_APP_LARGE_THUMBNAIL_URL;

  function renderFitems() {
    if (isVideo) {
      // If the video is hosted, play it in a custom modal window.
      if (isHosted) {
        return (
          <>
            <img
              src={imgURL + fitemId}
              alt="fitem"
              className="fitem_image_shadow fitem_item_wrapper"
              onClick={modalClick} // takes click function from "content" component.
            />
          </>
        );
      } else if (isHosted === false) {
        // Otherwise, render it in a new window with the download URL provided.
        return (
          <a href={downloadUrl} target="_new" className="fitem_item_wrapper">
            <img
              src={imgURL + fitemId}
              alt="fitem"
              className="fitem_image_shadow"
            />
          </a>
        );
      }
    } else {
      return (
        <a href={downloadUrl} target="_new" className="fitem_item_wrapper">
          <img
            src={imgURL + fitemId}
            alt="fitem"
            className="fitem_image_shadow"
          />
        </a>
      );
    }
  }

  return <>{renderFitems()}</>;
};

export default FItems;
