import React, { useEffect, useState } from "react";
import axios from "axios";
import Fitems from "./Fitems";

import "./InformationCenter.css";

const InformationCenter = () => {
  useEffect(() => {
    listProducts();
  }, []);

  const baseVideoUrl = process.env.REACT_APP_SCAPI;
  const largeImgUrl = process.env.REACT_APP_LARGE_THUMBNAIL_URL;
  const ROOT_URL = process.env.REACT_APP_API_URL;

  // Use two state objects. One for the products (SWIF, SDCF, etc.).
  // The second is for the fItems for each product.
  const [products, setProducts] = useState([]);
  const [fItems, setFitems] = useState([]);
  const [activeTab, setActiveTab] = useState(""); // When tab is clicked, this object gets populated and allows the tab to be "active"
  const [modal, setModal] = useState(false); // Controls the modal show/hide functionality with a simple boolean.
  // const [modalSku, setModalSku] = useState(""); // When we use the onclick function we store the sku, which is used in the modal to populate the correct video.
  const [fitemId, setFitemId] = useState("");
  const [itemPath, setItemPath] = useState("");

  // SP call that grabs all of the products to display in tabs
  function listProducts() {
    let private_placement_id = parseInt(
      sessionStorage.getItem("privatePlacementId")
    );
    let product_id = sessionStorage.getItem("productId");
    let view_only = sessionStorage.getItem("viewOnly");
    let profile_id = parseInt(sessionStorage.getItem("profileId"));
    var AuthData = {
      is_investor_portal: true,
      product_id: product_id,
      profile_id: profile_id,
      private_placement_id: private_placement_id,
      view_only: view_only,
    };

    var spFIInput = {
      spName: "ProductDataVault_ListSections",
      jsonInput: JSON.stringify(AuthData),
    };
    var FundData = JSON.stringify(spFIInput);
    var authenticationToken = sessionStorage.getItem("token");
    axios({
      method: "post",
      url: ROOT_URL,
      data: FundData,
      headers: {
        "Content-Type": "application/json",
        Authorization: authenticationToken,
      },
    })
      .then((response) => {
        const loginResponse = JSON.parse(response.data);
        // console.log(loginResponse.Rows[0].investor_portal_section_id);
        setProducts(loginResponse.Rows); // sets all the products in state
        // console.log(loginResponse.Rows)
        listFitems(loginResponse.Rows[0].investor_portal_section_id); // default first product when page loads
      })

      .catch((error) => {
        console.log("SP Call Error: " + error);
      });
  }

  // SP call that takes the product ID in and renders the fItems according to that ID
  function listFitems(fItem) {
    setActiveTab(fItem);
    // console.log(fItems);
    // set the fItems array in state to empty before making the call to empty out the previous content.
    setFitems([]);
    let sessionItems = JSON.parse(sessionStorage.getItem(fItem));
    // console.log(sessionItems);
    if (sessionItems) {
      setFitems(sessionItems);
      // console.log(sessionItems);
    } else {
      const ROOT_URL = process.env.REACT_APP_API_URL;
      let product_id = parseInt(sessionStorage.getItem("productId"));
      let profile_id = parseInt(sessionStorage.getItem("profileId"));
      let view_only = sessionStorage.getItem("viewOnly");
      var RequestData = {
        view_only: view_only,
        product_id: product_id,
        profile_id: profile_id,
        investor_portal_section_id: fItem,
      };
      var spFIInput = {
        spName: "ProductDataVault_ListSectionFItems",
        jsonInput: JSON.stringify(RequestData),
      };
      var FundData = JSON.stringify(spFIInput);
      var authenticationToken = sessionStorage.getItem("token");

      axios({
        method: "post",
        url: ROOT_URL,
        data: FundData,
        headers: {
          "Content-Type": "application/json",
          Authorization: authenticationToken,
        },
      })
        .then((response) => {
          const loginResponse = JSON.parse(response.data);
          // console.log(response);
          setFitems(loginResponse.Rows);
          let jsonRows = JSON.stringify(loginResponse.Rows);
          sessionStorage.setItem(fItem, jsonRows);
        })

        .catch((error) => {
          console.log("SP Call Error: " + error);
        });
    }
  }

  // After the state is set onload with the products, loop over it to display the tabs.
  // We use the products ID in the onclick method that allows us to display the proper fItems.
  const productArray = products.map(function (prodList) {
    return (
      <div
        // className="product_tab"
        className={
          activeTab === prodList.investor_portal_section_id
            ? "product_tab_active"
            : "product_tab"
        }
        key={prodList.investor_portal_section_id}
        onClick={() => listFitems(prodList.investor_portal_section_id)}
      >
        <span className="tab-item-text">{prodList.section_name}</span>
      </div>
    );
  });

  // This takes the state object of fItems that gets populated from the SP call and loops over them to display each item.
  const fItemsArray = fItems.map(function (itemsList) {
    // console.log(itemsList);
    return (
      <Fitems
        key={itemsList.item_sku}
        fitemId={itemsList.fitem_id}
        itemSku={itemsList.item_sku}
        itemName={itemsList.item_name}
        isVideo={itemsList.is_video}
        isHosted={itemsList.hosted_video}
        downloadUrl={itemsList.download_url}
        modalClick={(e) => handleClick(e, itemsList.item_sku)}
      />
    );
  });

  // handles the modal click. Takes the sku to pass through to the modalSku object for storing.
  function handleClick(e, itemSku) {
    // console.log(itemSku);
    e.preventDefault();
    itemPathRequest(itemSku);
  }

  const itemPathRequest = (sku) => {
    let private_placement_id = parseInt(
      sessionStorage.getItem("privatePlacementId"),
      10
    );
    let product_id = sessionStorage.getItem("productId");
    let investor_mode = sessionStorage.getItem("investorMode");
    let profile_id = parseInt(sessionStorage.getItem("profileId"), 10);
    let item_sku = sku;
    let view_only = sessionStorage.getItem("viewOnly");
    let requestData = {
      item_sku: item_sku,
      investor_mode: investor_mode,
      view_only: view_only,
      product_id: product_id,
      private_placement_id: private_placement_id,
      profile_id: profile_id,
    };
    let spRequest = {
      spName: "InvestorPortal_SelectFItem",
      jsonInput: JSON.stringify(requestData),
    };
    let spRequestData = JSON.stringify(spRequest);
    let authenticationToken = sessionStorage.getItem("token");

    axios({
      method: "post",
      url: ROOT_URL,
      data: spRequestData,
      headers: {
        "Content-Type": "application/json",
        Authorization: authenticationToken,
      },
    })
      .then((response) => {
        const success_response = JSON.parse(response.data);
        // console.log(success_response.Rows[0]);
        const sku = success_response.Rows[0].item_sku;
        const url = success_response.Rows[0].download_url;
        const isHosted = success_response.Rows[0].hosted_video;
        const path = success_response.Rows[0].document_path;
        const fItemId = success_response.Rows[0].fitem_id;

        // setModalSku(sku);
        setItemPath(path);
        setFitemId(fItemId);
        setModal(true);
      })

      .catch((error) => {
        console.log(error);
      });
  };

  // this runs on the close button on the modal. We have to set modalFitem to ('') or else the video will keep playing when you close the modal.
  function renderClose() {
    setModal(false);
    // setModalSku("");
    setItemPath("");
    setFitemId("");
  }

  // modal render. Shows when we set the modal state object to "true", hides when "false"
  function renderModal() {
    let videoPath = encodeURI(itemPath);
    const videoSrc = baseVideoUrl + videoPath;
    const poster = `${largeImgUrl}${fitemId}`;
    // console.log(videoPath + videoSrc)

    return (
      <div
        id="myModal"
        className={modal === false ? "modal-hide" : "modal-show"}
      >
        <div className="modal-content-cust">
          <div className="modal-header-cust">
            <span className="modal-close-cust" onClick={() => renderClose()}>
              &times;
            </span>
            <h5 className="fw-4">Media Player</h5>
          </div>
          <div className="modal-body-cust">
            <video
              className="modal-video-mod"
              controls
              key={videoSrc}
              autoPlay
              poster={poster}
            >
              <source src={videoSrc} type="video/mp4" />
              <source src={videoSrc} type="video/ogg" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="container-padding" id="information-center">
        <h1 className="section-title-gray">information center</h1>
        <div className="content_wrapper">
          <div className="product_tab_wrapper">{productArray}</div>
          <div className="fitem_wrapper">{fItemsArray}</div>
          {renderModal()}
        </div>
        {}
      </div>
    </>
  );
};

export default InformationCenter;
