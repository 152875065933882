import React, { useContext, useState, useEffect } from "react";
import { NavHashLink as NavLink } from "react-router-hash-link";
import { AuthContext } from "../../App";
import { useHistory } from "react-router-dom";

import navLogo from "../../assets/images/logo1-default.png";
import navLogoWhite from "../../assets/images/logo1-default-white.png";

import "./NaviBar.css";

const NaviBar = () => {
  const { state, dispatch } = useContext(AuthContext);
  const [transform, setTransform] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const [showBanner, setShowBanner] = useState(true);

  let history = useHistory();

  useEffect(() => {
    // initiate the event handler
    window.addEventListener("scroll", handleScroll, true);

    // this will clean up the event every time the component is re-rendered
    return function cleanup() {
      window.removeEventListener("scroll", handleScroll, true);
    };
  }, []);

  const handleScroll = () => {
    if (window.pageYOffset > 50) {
      setTransform(true);
    } else {
      setTransform(false);
    }
  };

  const handleHomePress = () => {
    history.push("/home");
    window.scrollTo(0, 0);
  };

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -85;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  // renders the class of the dropdown menu based on scroll and if the dropdown is showing.
  function dropDownRender() {
    if (transform === true && showDropDown === true) {
      return "nav-dropdown-show-white";
    } else if (transform === false && showDropDown === true) {
      return "nav-dropdown-show-clear";
    } else {
      return "nav-dropdown-hide";
    }
  }

  const logOut = () => {
    dispatch({ type: "LOGOUT" });
    history.push("/");
  };

  return (
    <>
      <div
        className={
          transform === true
            ? "nav-main-wrapper-white"
            : "nav-main-wrapper-clear"
        }
      >
        <div className={showBanner ? "closing-bar-show" : "closing-bar-hide"}>
          This offering is closed to new investments
          <span
            className="banner-close-button"
            onClick={() => setShowBanner(false)}
          >
            X
          </span>
        </div>
        <div className="nav-container">
          {transform === true ? (
            <img
              src={navLogo}
              className="nav-logo"
              alt="sdcf logo"
              onClick={() => {
                handleHomePress();
                setShowDropDown(false);
              }}
            />
          ) : (
            <img
              src={navLogoWhite}
              className="nav-logo"
              alt="sdcf logo"
              onClick={() => {
                handleHomePress();
                setShowDropDown(false);
              }}
            />
          )}
          {/* dropdown nav for mobile */}
          <div className={dropDownRender()}>
            <ul className="mobile-ul">
              <li>
                <NavLink
                  smooth
                  to="#"
                  activeClassName="selected"
                  className={transform === true ? "d-green" : "white"}
                  onClick={() => {
                    setShowDropDown(false);
                  }}
                >
                  HOME
                </NavLink>
              </li>
              <li>
                <NavLink
                  smooth
                  to="/home/#spotlight"
                  activeClassName="selected"
                  className={transform === true ? "d-green" : "white"}
                  scroll={(el) => scrollWithOffset(el)}
                  onClick={() => {
                    setShowDropDown(false);
                  }}
                >
                  SPOTLIGHT
                </NavLink>
              </li>
              {/* <li>
                <NavLink
                  smooth
                  to="/home/#data-centers"
                  activeClassName="selected"
                  className={transform === true ? "d-green" : "white"}
                  scroll={(el) => scrollWithOffset(el)}
                  onClick={() => {
                    setShowDropDown(false);
                  }}
                >
                  DATA CENTERS
                </NavLink>
              </li> */}
              {/* <li>
                <NavLink
                  smooth
                  to="/home/#our-strategy"
                  activeClassName="selected"
                  className={transform === true ? "d-green" : "white"}
                  scroll={(el) => scrollWithOffset(el)}
                  onClick={() => {
                    setShowDropDown(false);
                  }}
                >
                  OUR STRATEGY
                </NavLink>
              </li> */}
              {/* <li>
                <NavLink
                  smooth
                  to="/home/#asset-gallery"
                  activeClassName="selected"
                  className={transform === true ? "d-green" : "white"}
                  scroll={(el) => scrollWithOffset(el)}
                  onClick={() => {
                    setShowDropDown(false);
                  }}
                >
                  ASSET GALLERY
                </NavLink>
              </li> */}
              <li>
                <NavLink
                  smooth
                  to="/home/#information-center"
                  activeClassName="selected"
                  className={transform === true ? "d-green" : "white"}
                  scroll={(el) => scrollWithOffset(el)}
                  onClick={() => {
                    setShowDropDown(false);
                  }}
                >
                  INFORMATION CENTER
                </NavLink>
              </li>
              {/* <li onClick={() => dispatch({ type: "LOGOUT" })}> */}
              <li onClick={logOut}>
                <a
                  href=""
                  className={transform === true ? "d-green uc" : "white uc"}
                >
                  Log out
                </a>
              </li>
            </ul>
          </div>
          <div
            className="navbar-mobile-hamburger"
            onClick={() => setShowDropDown(!showDropDown)}
          >
            {/* <span>M</span> */}
            <div
              className={
                transform === true
                  ? "navbar-navline-gray"
                  : "navbar-navline-white"
              }
            ></div>
            <div
              className={
                transform === true
                  ? "navbar-navline-gray"
                  : "navbar-navline-white"
              }
            ></div>
            <div
              className={
                transform === true
                  ? "navbar-navline-gray"
                  : "navbar-navline-white"
              }
            ></div>
          </div>
          <div className="nav-links-container">
            <ul className="nav-link-ul">
              <li>
                <NavLink
                  smooth
                  to="#"
                  activeClassName="selected"
                  className={transform === true ? "d-green" : "white"}
                >
                  HOME
                </NavLink>
              </li>
              <li>
                <NavLink
                  smooth
                  to="/home/#spotlight"
                  activeClassName="selected"
                  className={transform === true ? "d-green" : "white"}
                  scroll={(el) => scrollWithOffset(el)}
                  onClick={() => {
                    setShowDropDown(false);
                  }}
                >
                  SPOTLIGHT
                </NavLink>
              </li>
              {/* <li>
                <NavLink
                  smooth
                  to="/home/#data-centers"
                  activeClassName="selected"
                  className={transform === true ? "d-green" : "white"}
                  scroll={(el) => scrollWithOffset(el)}
                >
                  DATA CENTERS
                </NavLink>
              </li> */}
              {/* <li>
                <NavLink
                  smooth
                  to="/home/#our-strategy"
                  activeClassName="selected"
                  className={transform === true ? "d-green" : "white"}
                  scroll={(el) => scrollWithOffset(el)}
                >
                  OUR STRATEGY
                </NavLink>
              </li> */}
              {/* <li>
                <NavLink
                  smooth
                  to="/home/#asset-gallery"
                  activeClassName="selected"
                  className={transform === true ? "d-green" : "white"}
                  scroll={(el) => scrollWithOffset(el)}
                >
                  ASSET GALLERY
                </NavLink>
              </li> */}
              <li>
                <NavLink
                  smooth
                  to="/home/#information-center"
                  activeClassName="selected"
                  className={transform === true ? "d-green" : "white"}
                  scroll={(el) => scrollWithOffset(el)}
                >
                  INFORMATION CENTER
                </NavLink>
              </li>
              {/* <li onClick={() => dispatch({ type: "LOGOUT" })}> */}
              <li onClick={logOut}>
                <a
                  href=""
                  className={transform === true ? "d-green uc" : "white uc"}
                >
                  Log out
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default NaviBar;
