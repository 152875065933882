import React, { useReducer, createContext, useEffect } from "react";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "./App.css";

import LoginPage from "./login/LoginPage";
import HomePage from "./home/Home";

// Auth Context will pass the auth state from this component to any other component that needs it.
export const AuthContext = createContext();
const initialState = {
  isAuthenticated: false,
  user: null,
  token: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      localStorage.setItem("user", JSON.stringify(action.payload.profile_id));
      localStorage.setItem("token", JSON.stringify(action.payload.token));
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        token: action.payload.token,
      };
    case "LOGOUT":
      sessionStorage.clear();
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    default:
      return state;
  }
};

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    caches.keys().then(function (names) {
      console.log(names);
      for (let name of names) caches.delete(name);
    });
    const user = sessionStorage.getItem("profileId");
    const token = sessionStorage.getItem("token");

    if (user && token) {
      dispatch({
        type: "LOGIN",
        payload: {
          user,
          token,
        },
      });
    }
  }, []);
  return (
    <Router>
      <Switch>
        <AuthContext.Provider value={{ state, dispatch }}>
          <div className="App">
            {/* {!state.isAuthenticated ? <LoginPage /> : <HomePage />} */}
            <Route exact path="/">
              <LoginPage />
            </Route>
            <Route path="/home">
              {state.isAuthenticated ? <HomePage /> : <LoginPage />}
            </Route>
            <Route path="/ss">
              <LoginPage />
            </Route>
          </div>
        </AuthContext.Provider>
      </Switch>
    </Router>
  );
}

export default App;
