import React, { Component } from "react";
import axios from "axios";
import { Row, Col, Collapse } from "react-bootstrap";

import SpotlightCard from "./SpotlightCard";

import "./Spotlight.css";

class Spotlight extends Component {
  constructor() {
    super();
    this.state = {
      asOfDate: "",
      tiaPriceName: "",
      tiaPriceFootnote: "",
      tiaPriceValue: "",
      hightlightData: [],
      footnoteData: [],
      open: false,
    };
  }
  componentDidMount() {
    const ROOT_URL = process.env.REACT_APP_API_URL;
    let product_id = parseInt(sessionStorage.getItem("productId"));
    let profile_id = parseInt(sessionStorage.getItem("profileId"), 10);
    let investor_mode = sessionStorage.getItem("investorMode");
    let private_placement_id = sessionStorage.getItem("private_placement_id");
    let view_only = sessionStorage.getItem("viewOnly");
    // First set of request data for the highlights
    var RequestData = {
      investor_mode: investor_mode,
      view_only: view_only,
      product_id: product_id,
      profile_id: profile_id,
      private_placement_id: private_placement_id,
      //   investor_portal_section_id: eventKey
    };
    var spFIInput = {
      spName: "ProductHighlights_ListHighlights",
      jsonInput: JSON.stringify(RequestData),
    };
    // this will be used as input for the axios call for the highlights
    var HightlightDataInput = JSON.stringify(spFIInput);
    var authenticationToken = sessionStorage.getItem("token");

    // Second set of request data for the footnotes
    let RequestDataFootnotes = {
      product_id: product_id,
    };
    let spInput2 = {
      spName: "ProductHighlights_ListHighlightFootnotes",
      jsonInput: JSON.stringify(RequestDataFootnotes),
    };
    // this will be used as input for the axios call for the footnotes
    const FootnotesDataInput = JSON.stringify(spInput2);

    // First request (highlights data)
    const requestOne = axios({
      method: "post",
      url: ROOT_URL,
      data: HightlightDataInput,
      headers: {
        "Content-Type": "application/json",
        Authorization: authenticationToken,
      },
    });

    // Second request (highlights footnotes data)
    const requestTwo = axios({
      method: "post",
      url: ROOT_URL,
      data: FootnotesDataInput,
      headers: {
        "Content-Type": "application/json",
        Authorization: authenticationToken,
      },
    });

    axios
      .all([requestOne, requestTwo])
      .then(
        axios.spread((...responses) => {
          const highlightsResponse = JSON.parse(responses[0].data);
          const footnotesResponse = JSON.parse(responses[1].data);

          console.log(highlightsResponse);
          console.log(footnotesResponse);

          //0 in prod, 1 in test
          const asOfDate = highlightsResponse.Rows[0].highlight_value;
          // console.log(asOfDate);
          // 1 in prod, 2 in test
          const tiaPriceName = highlightsResponse.Rows[1].highlight_name;
          // 1 in prod, 2 in test
          const tiaPriceFootnote = highlightsResponse.Rows[1].footnote_number;
          // 1 in prod, 2 in test
          const tiaPriceValue = highlightsResponse.Rows[1].highlight_value;

          const highlightData = highlightsResponse.Rows;
          console.log(highlightData);
          //(0, 2) in prod, (0, 3) in test
          highlightData.splice(0, 2);

          // Footnotes array that came back from the SP
          const footnotes = footnotesResponse.Rows;

          this.setState({
            asOfDate: asOfDate,
            tiaPriceName: tiaPriceName,
            tiaPriceFootnote: tiaPriceFootnote,
            tiaPriceValue: tiaPriceValue,
            highlightData: highlightData,
            footnoteData: footnotes,
          });
        })
      )
      .catch((errors) => {
        console.log(errors);
      });
  }

  // method that renders the footnote data we display under the highlights section.
  renderFootnotes() {
    return this.state.footnoteData.map((footnote) => {
      return (
        <p className="footnotes-para">
          <sup>{footnote.footnote_number} </sup>
          {footnote.footnote_text}
        </p>
      );
    });
  }

  render() {
    const highlightItems = this.state.highlightData
      ? this.state.highlightData.map((highlightItem) => {
          return (
            <SpotlightCard
              key={highlightItem.highlight_sort_order}
              name={highlightItem.highlight_name}
              value={highlightItem.highlight_value}
              // for the image, use the highlight ID and concat on to scapitest.scadvisoryservices.com/HighlightImage?id=${img}
              img={highlightItem.highlight_id}
              footnote={highlightItem.footnote_number}
            />
          );
        })
      : null;

    return (
      <div className="highlights-row-container" id="spotlight">
        <Row className="highlights_header">
          <h2 className="section-title-gray">SPOTLIGHT</h2>
        </Row>
        <Row className="highlights_content_row">
          <Col xs={12} lg={5}>
            <div className="tap-number">
              <span className="tap-number-numtext">
                {this.state.tiaPriceValue}
              </span>
              <span className="tap-number-text">
                {this.state.tiaPriceName}
                <sup>{this.state.tiaPriceFootnote}</sup>
              </span>
            </div>
          </Col>
          <Col xs={12} lg={3} className="tap-date">
            <span className="highlights_asof_date">
              Portfolio Current as of: {this.state.asOfDate}
            </span>
          </Col>
        </Row>
        <Row className="highlights-images-1">{highlightItems}</Row>
        <Row className="footnotes-row">
          <span
            onClick={() => this.setState({ open: !this.state.open })}
            className="footnotes-title-span"
          >
            Click here to view disclosures &#9660;
          </span>
        </Row>
        <Row
          className={
            this.state.open === true ? "footnote-show" : "footnote-hide"
          }
        >
          <div className="footnote-container">{this.renderFootnotes()}</div>
        </Row>
      </div>
    );
  }
}

export default Spotlight;
