import React from "react";
import { Col, Media } from "react-bootstrap";

import "./Spotlight.css";

const SpotlightCard = (props) => {
  const envType = process.env.REACT_APP_ENV_TYPE;

  let highLightImgURL =
    envType === "test"
      ? "https://scapitest.stratcap.com/HighlightImage?id="
      : "https://scapi.stratcap.com/HighlightImage?id=";

  return (
    <Col xs={12} lg={3}>
      <div className="highlightcard_media_center spotlight-card-wrapper">
        <div>
          <img
            width={100}
            height={100}
            src={`${highLightImgURL}${props.img}`}
            alt="thumbnail"
            className="port-img-padding"
          />
        </div>
        <div>
          <p className="portfolio-media-header">
            {props.name}
            <sup className="portfolio-media-header">{props.footnote}</sup>
          </p>
          <div className="media_heading_blue">{props.value}</div>
        </div>
      </div>
    </Col>
  );
};

export default SpotlightCard;
